import axios from 'axios';
import dayjs from 'dayjs';
import { reactive } from 'vue';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import webSocketService from '@/app/services/socket.service';
import legalCaseSortService from '@/case-list/services/legalcase.sort.service';
import { legalCaseAPIClient } from '@/common/clients/legalcase.api.client';
import { sourceFileClient } from '@/common/clients/sourcefile.client';
import { authService } from '@/common/services/auth/auth.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';
import logger from '@/common/services/logging';
import { API } from '@/common/types/api.types';
import { ISODateTimeString, ObjectValues, UUID } from '@/common/types/common.types';

const CASE_SERVICE_STATUS = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  REFRESHING: 'REFRESHING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
} as const;
type CaseServiceStatus = ObjectValues<typeof CASE_SERVICE_STATUS>;

export interface LegalCase extends API.LegalCase.ListResponse {}

interface NewSourceFiles {
  originalFileUris: string[];
  uploadFilenames: string[];
  folder: string;
  settings: Record<string, unknown>;
}
interface NewSourceFilesWithCaseId extends NewSourceFiles {
  legalCaseId: UUID;
}

interface ServiceState {
  status: CaseServiceStatus;
  cases: LegalCase[];
  casesLoadedTimestamp: null | ISODateTimeString;
}

// Event Interfaces
export enum EventType {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  SYNCHRONIZE = 'SYNCHRONIZE',
}
export interface BaseEvent {
  eventType: EventType;
  timestamp: ISODateTimeString; // Use ISO 8601 format for dates in TS
}

export interface LegalCaseCreateEventWs {
  baseEvent: BaseEvent;
  legalCaseId: string;
}

export interface LegalCaseDeleteEventWs {
  baseEvent: BaseEvent;
  legalCaseId: string;
}

export interface LegalCaseUpdateEventWs {
  baseEvent: BaseEvent;
  legalCaseId: string;
}

class LegalCaseService {
  state: ServiceState;

  private updateQueue: Set<string> = new Set();

  private controller: AbortController | null = null;

  private updateBatchTimeout: ReturnType<typeof setTimeout> | null = null;

  private readonly BATCH_DELAY = 500;

  constructor() {
    this.state = reactive({
      status: 'INIT',
      cases: [],
      casesLoadedTimestamp: null,
    });

    broadcastEventBus.subscribe('LEGALCASE_SORT_CHANGE_EVENT', () => this.sortCases());
  }

  isLoading() {
    return this.state.status === 'LOADING';
  }

  isRefreshing() {
    return this.state.status === 'REFRESHING';
  }

  isLoaded() {
    return this.state.status === 'LOADED' || this.state.status === 'REFRESHING';
  }

  isErrored() {
    return this.state.status === 'ERROR';
  }

  async load() {
    if (this.state.status !== 'INIT') {
      return;
    }

    appService.setLoading(true);
    this.state.status = 'LOADING';
    try {
      this.setCases(await legalCaseAPIClient.fetchList());
      this.state.status = 'LOADED';
    } catch (e) {
      handleError($t('CaseList.casesCannotBeLoaded'), e);
      this.setCases([]);
      this.state.casesLoadedTimestamp = null;
      this.state.status = 'ERROR';
    } finally {
      appService.setLoading(false);
      this.state.casesLoadedTimestamp = dayjs.utc().format();
    }
  }

  async refresh() {
    if (this.state.status !== 'LOADED') {
      return;
    }
    this.state.status = 'REFRESHING';

    const url = `${config.API.CASES_UPDATES_ENDPOINT}/${this.state.casesLoadedTimestamp}`;
    axios
      .get(url)
      .then((response) => {
        this.setUpdatedCases(response.data);
        this.state.status = 'LOADED';
      })
      .catch((e) => {
        handleError($t('CaseList.casesCannotBeLoaded'), e);
        this.setCases([]);
        this.state.casesLoadedTimestamp = null;
        this.state.status = 'ERROR';
      })
      .finally(() => {
        this.state.casesLoadedTimestamp = dayjs.utc().format();
      });
  }

  async addCase(newLegalCase: API.LegalCase.CreateUpdateRequest, newSourceFile: NewSourceFiles) {
    const newLegalCaseId = await legalCaseAPIClient.create(newLegalCase);
    if (!newLegalCaseId) return false;

    const sourceFileRequest = { ...newSourceFile, legalCaseId: newLegalCaseId };
    const allSourceFilesUploaded = await this.postSourceFiles(newLegalCaseId, sourceFileRequest);
    const createdLegalCase = await legalCaseAPIClient.fetch(newLegalCaseId);
    if (!createdLegalCase) return false;
    return allSourceFilesUploaded;
  }

  async postSourceFiles(legalCaseId: UUID, newSourceFile: NewSourceFilesWithCaseId) {
    const { originalFileUris, uploadFilenames, ...templateSourceFile } = newSourceFile;
    if (originalFileUris.length > 100) {
      handleError($t('CaseList.AddEditCase.documentsRequirement'));
      return false;
    }

    const puts = [];
    for (let i = 0; i < originalFileUris.length; i++) {
      puts.push(
        sourceFileClient.post(legalCaseId, {
          ...templateSourceFile,
          tempFileUri: originalFileUris[i],
          uploadFilename: uploadFilenames[i],
        }),
      );
    }
    const responses = await Promise.all(puts);
    const errors = responses.filter((x) => x.error === 'SOURCEFILE_EXISTS_IN_LEGALCASE' && x.status === 'ERROR');
    if (errors.length !== 0) {
      handleError($t('CaseList.certainFilesCannotBeUploaded', [errors.map((x) => x.uploadFilename).join(' ,')]));
      return false;
    }

    return true;
  }

  async editCase(legalCaseId: UUID, caseData: API.LegalCase.CreateUpdateRequest) {
    await legalCaseAPIClient.update(legalCaseId, caseData);
    this.sortCases();
  }

  async toggleArchiveCase(changedCase: LegalCase) {
    const newStatus = changedCase.legalCaseStatus === 'OPEN' ? 'ARCHIVED' : 'OPEN';
    await legalCaseAPIClient.updateStatus(changedCase.id, newStatus);
  }

  async deleteCase(caseId: UUID) {
    const result = await legalCaseAPIClient.delete(caseId);
    if (result) {
      this.setCases(this.state.cases.filter((c) => c.id !== caseId));
    }
  }

  unsubscribeFromWebSocketEvents() {
    const tenantId = authService.state.data?.tenant.id;
    webSocketService.unsubscribeFromTopic(`/topic/${tenantId}/legalcases`);
  }

  // WebSocket subscription for real-time updates
  subscribeToWebSocketEvents() {
    const tenantId = authService.state.data?.tenant.id;
    webSocketService.subscribeToTopic(`/topic/${tenantId}/legalcases`, (message) => {
      const parsedMessage = JSON.parse(message.body);
      const { eventType } = parsedMessage.baseEvent;

      switch (eventType) {
        case EventType.CREATE:
          this.handleCreateEvent(parsedMessage);
          break;
        case EventType.UPDATE:
          this.queueCaseUpdate(parsedMessage.legalCaseId);
          break;
        case EventType.DELETE:
          this.handleDeleteEvent(parsedMessage);
          break;
        case EventType.SYNCHRONIZE:
          this.load();
          break;
        default:
          logger.warn(`Unknown WebSocket event type: ${eventType}`);
      }
    });
  }

  // Handle CREATE event
  async handleCreateEvent(event: LegalCaseCreateEventWs) {
    const newCase = await legalCaseAPIClient.fetchListResponse(event.legalCaseId);
    if (newCase) {
      this.state.cases.push(newCase);
      this.sortCases();
    }
  }

  // Handle UPDATE event

  private async queueCaseUpdate(legalCaseId: string) {
    this.updateQueue.add(legalCaseId);
    // Clear the previous timeout if it exists
    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();
    const { signal } = this.controller;

    // Schedule the batch processing
    setTimeout(async () => {
      if (!signal.aborted) {
        await this.processBatchUpdates();
      }
    }, this.BATCH_DELAY);
  }

  private async processBatchUpdates() {
    // Abort any ongoing actions if the controller exists
    if (this.controller) {
      this.controller.abort();
      this.controller = null;
    }

    const caseIds = Array.from(this.updateQueue);
    this.updateQueue.clear();

    if (caseIds.length === 0) return;

    // Create a new controller for this batch process
    this.controller = new AbortController();
    const { signal } = this.controller;

    try {
      // Fetch all updates with support for aborting if needed
      const updatedCases = await Promise.all(caseIds.map((id) => legalCaseAPIClient.fetchListResponse(id, { signal })));

      const validCases = updatedCases.filter((c): c is LegalCase => c !== null);
      if (validCases.length > 0) {
        this.setUpdatedCases(validCases);
      }
    } catch (error: any) {
      // Handle abort errors separately if needed
      if (error.name === 'AbortError') {
        logger.info('Batch update aborted.');
      } else {
        handleError($t('CaseList.casesCannotBeLoaded'), error);
      }
    } finally {
      // Reset the controller after the process is complete
      this.controller = null;
    }
  }

  handleDeleteEvent(event: LegalCaseDeleteEventWs) {
    const deletedCaseId = event.legalCaseId;
    this.state.cases = this.state.cases.filter((c) => c.id !== deletedCaseId);
    this.sortCases();
  }

  async changeOwner(legalCase: LegalCase) {
    const { userId } = authService.state;
    if (userId && legalCase.owner !== (userId as UUID)) {
      const legalCaseRequest = {
        reference: legalCase.reference,
        owner: userId as UUID,
        accessGroup: legalCase.accessGroup ?? '',
        labeled: legalCase.labeled,
      };
      await this.editCase(legalCase.id, legalCaseRequest);
    }
  }

  setUpdatedCases(updatedCases: LegalCase[]) {
    if (updatedCases.length === 0) return;

    const newCases = [...this.state.cases];
    for (const updated of updatedCases) {
      const existing = newCases.find((item) => item.id === updated.id);
      if (existing) {
        Object.assign(existing, updated);
      } else {
        newCases.push(updated);
      }
    }
    this.setCases(newCases);
  }

  setCases(cases: LegalCase[]) {
    this.state.cases = legalCaseSortService.getSortedCases(cases);
  }

  sortCases() {
    this.state.cases = legalCaseSortService.getSortedCases(this.state.cases);
  }
}

export const legalCaseService = new LegalCaseService();
export const LegalCaseServiceClass = LegalCaseService;
