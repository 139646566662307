import dayjs from 'dayjs';

import { $t } from '@/app/i18n/i18n.service';
import { getLabel } from '@/case-detail/search/services/suggestions.helper';
import detailViewService from '@/case-detail/services/detail.view.service';
import userAnnotationService from '@/case-detail/subviews/document/annotations/services/user.annotation.service';
import { isBadOcr, isDiagnosisCodeMatchesFilter, isToReview } from '@/case-detail/subviews/document/services/document.helper';
import { Document } from '@/case-detail/subviews/document/services/document.service';
import pagereadService from '@/case-detail/subviews/document/services/pageread.service';
import labelService from '@/case-detail/subviews/labels/services/label.service';
import { WorkInabilityTemplate } from '@/case-detail/subviews/work-inabilities/services/workinability.service';
import { formatToLocale } from '@/common/services/date.utils';
import { folderService } from '@/common/services/folder.service';
import { UUID } from '@/common/types/common.types';

export type DocumentFilterTypes = {
  // variant 1.1: { code: "S72.8", title: string }
  // variant 1.2: { code: "S00-T99", title: string }
  // variant 2: { code: "S82.8", title: string, documents: [ {id: string, page: number} ] }
  // variant 3: { code: "S82.8", title: string, documents: [ {id: string, page: number}, tags: [string] ] }
  diagnosis: null | {
    code: string;
    title: string;
    documents?: { id: string; page: number }[];
    tags?: [string];
  };

  labels: UUID[];
  important: boolean;
  deleted: boolean;
  duplicate: boolean;
  duplicateCandidates: boolean;
  unread: boolean;
  toReview: boolean;
  badOcr: boolean;
  minDate: null | number;
  maxDate: null | number;
  // 'RECEIPT_DATE' - date, doc added to system
  // 'ISSUE_DATE' - extracted from doc
  filterMode: 'RECEIPT_DATE' | 'ISSUE_DATE';
  newSinceLastVisit: boolean;
  showMissingDate: boolean;
  userAnnotationKeys: string[];
  notExportedDocuments: null | UUID[];
  notSharedDocuments: null | UUID[];
  sharedDocuments: null | UUID[];
  folder: string[]; // folder keys, i.e. 'internal_pension'
  title: null | string;
  ref: null | string;
  author: null | string;
  authorInstitution: null | string;
  recipient: null | string;
  recipientInstitutions: null | string;
  workInability: null | WorkInabilityTemplate;
  touched: boolean;
  userCorrection: boolean;
  documentIds: string[];
  timeline: string[];
};

export type DocumentFilterKey = keyof DocumentFilterTypes;

export interface FilterMetadata<K extends DocumentFilterKey> {
  key: K;
  default: DocumentFilterTypes[K];
  filterFn: (document: Document, value: DocumentFilterTypes[K], allValues: Partial<DocumentFilterTypes>) => boolean;

  // for filter chips in doclist header
  text: (value: DocumentFilterTypes[K]) => string;
  icon: (value: DocumentFilterTypes[K]) => string;
  tooltip?: (value: DocumentFilterTypes[K]) => string;
  color?: (value: DocumentFilterTypes[K]) => string;

  // for search suggestions
  path?: string;
  compare?: (query: string, value: string) => boolean;
}

export type FilterType<K extends DocumentFilterKey> = DocumentFilterTypes[K];

type DocumentFilters = {
  [K in DocumentFilterKey]: FilterMetadata<K>;
};

export const documentFilters = {
  diagnosis: {
    key: 'diagnosis',
    default: null,
    filterFn: (document, value) => {
      if (!value) return true;

      // set via documents (diagnosis explorer panel)
      if (value.documents?.length && !value.documents.map((d) => d.id).includes(document.id)) {
        return false;
      }

      const documentDiagnosisCodes = document.diagnoses.flatMap((d) => d.icd10Code);
      if (!documentDiagnosisCodes.some((code) => isDiagnosisCodeMatchesFilter(code, value.code))) {
        return false;
      }

      return true;
    },
    text: (value) => value?.title ?? '',
    icon: () => 'mdi-stethoscope',
    color: () => 'primary',
  },

  labels: {
    key: 'labels',
    default: [],
    filterFn: (document, value) => {
      if (!value.length) return true;

      // <uuid>_other are artificial ids created on FE side - they include everyone not fitting into any sublabel
      const realLabelIds = value.map((l) => l.replace('_other', ''));
      return document.labels.some((l) => realLabelIds.includes(l));
    },
    text: (value) => {
      const l = getLabel(labelService, value);
      return l?.title ?? '';
    },
    icon: (value) => {
      const l = getLabel(labelService, value);
      return l?.icon ?? 'mdi-label';
    },
    color: (value) => {
      const l = getLabel(labelService, value);
      return l?.color ?? '';
    },
  },

  important: {
    key: 'important',
    default: false,
    filterFn: (document, value) => !value || document.metadata.IMPORTANT.value === 'true',
    text: () => '',
    icon: () => 'mdi-star',
    tooltip: () => $t('CaseDetail.DocumentFilters.Important.tooltip'),
  },

  deleted: {
    key: 'deleted',
    default: false,
    filterFn: (document, value) => !value || document.status.startsWith('DELETED'),
    text: () => '',
    icon: () => 'mdi-delete-outline',
    tooltip: () => $t('CaseDetail.DocumentFilters.Deleted.tooltip'),
  },

  duplicate: {
    key: 'duplicate',
    default: false,
    filterFn: (document, value) => !value || document.duplicates.length > 0,
    text: () => '',
    icon: () => 'mdi-content-duplicate',
    tooltip: () => $t('CaseDetail.DocumentFilters.Duplicate.tooltip'),
  },

  duplicateCandidates: {
    key: 'duplicateCandidates',
    default: false,
    filterFn: (document, value) => !value || document.duplicateCandidates.length > 0,
    text: () => $t('CaseDetail.DocumentFilters.DuplicateCandidates.text'),
    icon: () => 'mdi-file-question-outline',
    tooltip: () => $t('CaseDetail.DocumentFilters.DuplicateCandidates.tooltip'),
  },

  unread: {
    key: 'unread',
    default: false,
    filterFn: (document, value) => !value || pagereadService.isUnread(document),
    text: () => $t('CaseDetail.DocumentFilters.Unread.text'),
    icon: () => 'mdi-eye-remove',
    tooltip: () => $t('CaseDetail.DocumentFilters.Unread.tooltip'),
  },

  toReview: {
    key: 'toReview',
    default: false,
    filterFn: (document, value) => !value || isToReview(document),
    text: () => $t('CaseDetail.DocumentFilters.ToReview.text'),
    icon: () => 'mdi-file-edit-outline',
    tooltip: () => $t('CaseDetail.DocumentFilters.ToReview.tooltip'),
  },

  badOcr: {
    key: 'badOcr',
    default: false,
    filterFn: (document, value) => !value || isBadOcr(document),
    text: () => $t('CaseDetail.DocumentFilters.BadOcr.text'),
    icon: () => 'mdi-ocr',
    tooltip: () => $t('CaseDetail.DocumentFilters.BadOcr.tooltip'),
  },

  // These 3 always work together
  minDate: {
    key: 'minDate',
    default: null,
    filterFn: () => true,
    text: (value: number | string | null) => $t('CaseDetail.DocumentFilters.FromDate.text', [formatToLocale(dayjs(value), 'l')]),
    icon: () => 'mdi-calendar-range',
  },
  maxDate: {
    key: 'maxDate',
    default: null,
    filterFn: () => true,
    text: (value: number | string | null) => $t('CaseDetail.DocumentFilters.ToDate.text', [formatToLocale(dayjs(value), 'l')]),
    icon: () => 'mdi-calendar-range',
  },
  filterMode: {
    key: 'filterMode',
    default: 'ISSUE_DATE',
    filterFn: (document, value, allValues) => {
      if (!allValues.minDate || !allValues.maxDate) return true;

      const docDate = document.metadata?.[value].value;
      if (docDate) {
        const ms = dayjs(docDate).valueOf();
        return ms >= allValues.minDate && ms <= allValues.maxDate;
      }

      return true;
    },
    text: () => '',
    icon: () => 'mdi-calendar-blank',
  },

  newSinceLastVisit: {
    key: 'newSinceLastVisit',
    default: false,
    filterFn: (document, value) => {
      if (!value) {
        return true;
      }

      const currentCase = detailViewService.getCurrentLegalCase();
      // if no `currentCase` - we probably out of case detail view
      if (!currentCase) {
        return false;
      }

      const lastViewed = currentCase.lastOwnerActivity;
      if (!lastViewed) {
        // first time in case, all documents are new
        return true;
      }

      const lastViewedDate = dayjs(lastViewed);
      const uploadDate = dayjs(document.sourceFileUploaded);
      return uploadDate.isValid() && (uploadDate.isAfter(lastViewedDate) || uploadDate.isSame(lastViewedDate, 'day'));
    },
    text: () => '',
    icon: () => 'mdi-new-box',
  },

  showMissingDate: {
    key: 'showMissingDate',
    default: true,
    filterFn: (document, value) => value || !!document.metadata?.ISSUE_DATE.value,
    text: () => '',
    icon: () => 'mdi-calendar-question',
    tooltip: () => $t('CaseDetail.DocumentFilters.ShowMissingDate.tooltip'),
  },

  userAnnotationKeys: {
    key: 'userAnnotationKeys',
    default: [],
    filterFn: (document, value) => {
      if (!value.length) return true;

      // NOTE(ndv): we use startsWith to allow users to filter by both text and free comments by only setting the "USER_COMMENT" key prefix
      return value.every((annotationKey) => document.userAnnotations.find((annotation) => annotation.annotationKey.startsWith(annotationKey)));
    },
    text: (values) => {
      const mapped = values.map((v) => userAnnotationService.getAnnotationMapping(v)?.text);
      return mapped.filter((v) => !!v).join(', ');
    },
    icon: () => '',
    tooltip: (values) => {
      const mapped = values.map((v) => userAnnotationService.getAnnotationMapping(v)?.text);
      return mapped.filter((v) => !!v).join(', ');
    },
  },

  notExportedDocuments: {
    key: 'notExportedDocuments',
    default: null,
    filterFn: (document, value) => !value || !value.includes(document.id),
    text: () => $t('CaseDetail.DocumentFilters.NotExportedDocuments.text'),
    tooltip: () => $t('CaseDetail.DocumentFilters.NotExportedDocuments.tooltip'),
    icon: () => 'mdi-playlist-remove',
  },
  notSharedDocuments: {
    key: 'notSharedDocuments',
    default: null,
    filterFn: (document, value) => !value || !value.includes(document.id),
    text: () => $t('CaseDetail.Access.Dialog.documentsNotYetShared'),
    tooltip: () => $t('CaseDetail.Access.Dialog.documentsNotYetShared'),
    icon: () => 'mdi-key-remove',
  },
  sharedDocuments: {
    key: 'sharedDocuments',
    default: null,
    filterFn: (document, value) => !value || value.includes(document.id),
    text: () => $t('CaseDetail.Access.Dialog.sharedDocuments'),
    tooltip: () => $t('CaseDetail.Access.Dialog.sharedDocuments'),
    icon: () => 'mdi-key',
  },

  folder: {
    key: 'folder',
    default: [],
    filterFn: (document, value) => {
      if (!value.length) return true;

      const docInFolders = value.includes(document.metadata.FOLDER.value);
      const duplicateInFolders = document.duplicates.some((duplicate) => value.includes(duplicate.metadata.FOLDER.value));

      if (!docInFolders && duplicateInFolders) {
        document.duplicateMatched = true;
      }

      return docInFolders || duplicateInFolders;
    },
    text: (value: string[]) => (value.length === 1 ? folderService.getFolderText(value[0]) : ` ${value.length}`),
    icon: () => 'mdi-folder-open',
  },

  title: {
    key: 'title',
    default: null,
    filterFn: (document, value) => !value || document.metadata.TITLE.value.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    text: (value) => value ?? '',
    path: 'metadata.TITLE.value',
    compare: (query: string, value: string) => value.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    icon: () => 'mdi-format-title',
  },

  ref: {
    key: 'ref',
    default: null,
    filterFn: (document, value) => {
      if (!value) return true;

      const docRefMatches = document.metadata.REF.value.toString() === value.toString();
      const duplicateRefMatches = document.duplicates.some((d) => d.metadata.REF.value.toString() === value.toString());

      if (!docRefMatches && duplicateRefMatches) {
        document.duplicateMatched = true;
      }

      return docRefMatches || duplicateRefMatches;
    },
    text: (value) => value ?? '',
    path: 'metadata.REF.value',
    compare: (query: string, value: string) => value.toString() === query.toString(),
    icon: () => 'mdi-file-document-outline',
  },

  author: {
    key: 'author',
    default: null,
    filterFn: (document, value) => !value || document.metadata.AUTHOR.value.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    text: (value) => value ?? '',
    path: 'metadata.AUTHOR.value',
    compare: (query: string, value: string) => value.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    icon: () => 'mdi-account',
  },

  authorInstitution: {
    key: 'authorInstitution',
    default: null,
    filterFn: (document, value) => !value || document.metadata.AUTHOR_INSTITUTION.value.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    text: (value) => value ?? '',
    path: 'metadata.AUTHOR_INSTITUTION.value',
    compare: (query: string, value: string) => value.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    icon: () => 'mdi-office-building-outline',
  },

  recipient: {
    key: 'recipient',
    default: null,
    filterFn: (document, value) => !value || document.metadata.RECIPIENT.value.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    text: (value) => value ?? '',
    path: 'metadata.RECIPIENT.value',
    compare: (query: string, value: string) => value.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    icon: () => 'mdi-map-marker-account',
  },

  recipientInstitutions: {
    key: 'recipientInstitutions',
    default: null,
    filterFn: (document, value) => !value || document.metadata.RECIPIENT_INSTITUTION.value.toLowerCase().indexOf(value.toLowerCase()) !== -1,
    text: (value) => value ?? '',
    path: 'metadata.RECIPIENT_INSTITUTION.value',
    compare: (query: string, value: string) => value.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    icon: () => 'mdi-office-building-marker',
  },

  workInability: {
    key: 'workInability',
    default: null,
    filterFn: (document, value) => !value || value.documentId === document.id,
    text: (value) => {
      if (!value) {
        return $t('CaseDetail.DocumentFilters.WorkInability.defaultText');
      }
      if (value.to) {
        return `${value.percent} % ${formatToLocale(value.from)} - ${formatToLocale(value.to)}`;
      }
      return `${value.percent} % ${formatToLocale(value.from)}`;
    },
    icon: () => '',
  },

  // any user annotation
  touched: {
    key: 'touched',
    default: false,
    filterFn: (document, value) => !value || document.userAnnotations.length > 0,
    text: () => $t('CaseDetail.DocumentFilters.Touched.text'),
    icon: () => 'mdi-file-document-edit-outline',
    tooltip: () => $t('CaseDetail.DocumentFilters.Touched.tooltip'),
  },

  userCorrection: {
    key: 'userCorrection',
    default: false,
    filterFn: (document, value) => !value || Object.values(document.metadata).some((m) => m.extractor === 'MANUAL'),
    text: () => $t('CaseDetail.DocumentFilters.UserCorrection.text'),
    icon: () => 'mdi-file-sign',
    tooltip: () => $t('CaseDetail.DocumentFilters.UserCorrection.tooltip'),
  },

  documentIds: {
    key: 'documentIds',
    default: [],
    filterFn: (document, value) => value.length === 0 || value.includes(document.id),
    text: () => '',
    icon: () => 'mdi-format-quote-open',
  },

  timeline: {
    key: 'timeline',
    default: [],
    filterFn: (document, value) => value.length === 0 || value.includes(document.id),
    text: () => '',
    icon: () => 'mdi-clock-outline',
  },
} satisfies DocumentFilters;

export const allDocumentFilterKeys = Object.keys(documentFilters) as DocumentFilterKey[];

export const defaultDocumentFilterValues = Object.fromEntries(
  Object.entries(documentFilters).map(([k, v]) => [k, v.default]),
) as unknown as DocumentFilterTypes;
