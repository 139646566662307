<template>
  <div>
    <div
      id="current-case"
      v-tippy="
        legalCase.inProgress && legalCase.ready
          ? $t('App.Bar.CaseDetailIndicator.caseIsProcessing')
          : legalCase.overDocumentCountLimit && legalCase.ready
            ? $t('CaseDetail.tooManyDocsPerformanceLimited')
            : $t('App.Bar.CaseDetailIndicator.defaultTooltip')
      "
      class="text-body-1 mr-5 d-flex align-center current-case-indicator"
    >
      <v-icon v-if="legalCase.inProgress && legalCase.ready" class="mr-3 bg-warning current-case-icon" size="small" color="white">
        mdi-sync mdi-spin
      </v-icon>
      <v-icon v-else-if="legalCase.overDocumentCountLimit && legalCase.ready" class="mr-3" color="warning" size="large"> mdi-alert </v-icon>
      <v-icon v-else class="mr-3" size="x-large"> mdi-account-box-outline </v-icon>

      <div class="d-flex flex-column justify-center mt-1">
        <div class="text-no-wrap d-flex ga-1 align-center data-hj-suppress case-title-wrapper">
          <div class="text-truncate case-display-label">
            {{ legalCase.displayLabel }}
          </div>
          {{ piiAge }}
        </div>
        <div class="text-caption text-grey text-no-wrap">
          {{ reference }}
        </div>
      </div>
    </div>

    <div class="d-flex menu-wrapper">
      <l-menu transition="scale-transition" activator="#current-case" class="elevation-0">
        <div class="menu-content bg-surface elevation-1 rounded">
          <v-list density="compact" class="pa-0">
            <v-list-item v-if="$hasPermission('SOURCEFILE_RUD')" data-testid="action_caseShowFiles" @click.stop="showFileManager(legalCase)">
              <v-list-item-title>{{ $t('CaseList.CaseTile.filesInThisCase') }}</v-list-item-title>
            </v-list-item>
            <template v-if="$hasPermission('LEGALCASE_UD') || $hasPermission('LEGALCASE_U_OWNER_META')">
              <v-list-item @click="showEditCase(legalCase)">
                <v-list-item-title>{{ $t('CaseList.CaseTile.editCaseData') }}</v-list-item-title>
              </v-list-item>
            </template>
            <template v-if="$hasPermission('LEGALCASE_UD')">
              <v-list-item
                data-testid="action_changeCaseOwner"
                @click="
                  appService.confirm(
                    $t('CaseDetail.changeOwnerConfirmationTitle'),
                    $t('CaseDetail.changeOwnerConfirmationMessage'),
                    $t('CaseDetail.changeOwnerConfirmationAction'),
                    changeOwner,
                  )
                "
              >
                <v-list-item-title>{{ $t('CaseList.CaseTile.changeOwner') }}</v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              v-if="$hasPermission('LEGALCASE_U_CLOSE')"
              @click="
                appService.confirm(
                  $t('CaseDetail.closeConfirmationTitle'),
                  $t('CaseDetail.closeConfirmationMessage', [legalCase.displayLabel]),
                  $t('CaseDetail.closeConfirmationAction'),
                  archiveCase,
                  'primary',
                )
              "
            >
              <v-list-item-title>{{ $t('CaseDetail.closeConfirmationAction') }}</v-list-item-title>
            </v-list-item>
          </v-list>

          <PiiQuickAccess />
        </div>
      </l-menu>
    </div>
  </div>
  <template v-if="currentCase">
    <FileManager :legal-case="currentCase" :visible="showFileManagerDialog" @close="closeFileManager()" />
    <AddEditCase :editing-mode="true" :input-case="currentCase" :visible="showEditCaseDialog" @close="closeEditCase()" />
  </template>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { defineComponent } from 'vue';

import appService from '@/app/services/app.service';
import PiiQuickAccess from '@/case-detail/app-bar/PiiQuickAccess.vue';
import detailViewService, { CurrentLegalCase } from '@/case-detail/services/detail.view.service';
import documentService from '@/case-detail/subviews/document/services/document.service';
import AddEditCase from '@/case-list/components/AddEditCase.vue';
import FileManager from '@/case-list/components/FileManager.vue';
import { legalCaseService } from '@/case-list/services/legalcase.service';
import { authService } from '@/common/services/auth/auth.service';

export default defineComponent({
  components: { FileManager, PiiQuickAccess, AddEditCase },

  data() {
    return {
      appService,
      documentService,
      showFileManagerDialog: false,
      showEditCaseDialog: false,
      currentCase: null as CurrentLegalCase | null,
    };
  },

  computed: {
    legalCase() {
      return detailViewService.getCurrentLegalCase() as CurrentLegalCase;
    },
    reference() {
      const reference = [this.legalCase?.reference, this.legalCase?.pii.CUSTOM_1.value].filter((ref) => ref && ref !== '').join(', ');
      return reference || this.$t('App.Bar.CaseDetailIndicator.noCaseReference');
    },
    piiAge() {
      const piiBirthDate = this.legalCase?.pii.PII_BIRTHDATE.value;
      if (!piiBirthDate || piiBirthDate === '') {
        return '';
      }
      const birthDate = dayjs(piiBirthDate);
      const todayDate = dayjs();
      const age = todayDate.diff(birthDate, 'year', false);
      return `(${age})`;
    },
  },

  methods: {
    async archiveCase() {
      await detailViewService.toggleCurrentLegalCaseArchive();
      appService.info(this.$t('CaseDetail.caseClosedMessage'));
      this.$router.push({
        name: 'case-list',
        params: { tenant: authService.state.data!.tenant.canonicalName },
      });
      this.$a.l(this.$a.e.CASE_ARCHIVE);
    },
    showFileManager(currentCase: CurrentLegalCase) {
      this.currentCase = { ...currentCase };
      this.showFileManagerDialog = true;
      this.$a.l(this.$a.e.CASE_FILEMANAGER_OPEN);
    },
    closeFileManager() {
      detailViewService.refreshCurrentLegalCase();
      this.documentService.load(this.legalCase.id);
      this.showFileManagerDialog = false;
      this.$a.l(this.$a.e.CASE_FILEMANAGER_CLOSE);
    },
    showEditCase(currentCase: CurrentLegalCase) {
      this.currentCase = { ...currentCase };
      this.showEditCaseDialog = true;
    },
    closeEditCase() {
      this.showEditCaseDialog = false;
    },
    async changeOwner() {
      await legalCaseService.changeOwner(this.legalCase);
      appService.info(this.$t('CaseDetail.changeOwnerConfirmation'));
    },
  },
});
</script>

<style scoped>
.current-case-indicator {
  cursor: pointer;
  line-height: 0.8rem;
  padding: 4px 12px 4px 12px;
  border-radius: 3px;
}

.current-case-indicator:hover {
  background-color: rgb(var(--v-theme-surface-darken-1));
}

.current-case-icon {
  width: 25px;
  height: 25px;
  border-radius: 25px;
}

.case-title-wrapper {
  margin-bottom: -2px;
}

.case-display-label {
  max-width: 120px;
  line-height: 1.2;
  display: inline-block;
}

.menu-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
}

.menu-content {
  display: flex;
  border-radius: 4px;
}
</style>
