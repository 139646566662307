import OpenAPIClientAxios from 'openapi-client-axios';

import { config } from '@/app/config';
import { Client as UserClient, Client as UserPreferencesClient } from '@/common/generated-types/openapi';
import OPENAPI_DEFINITION from '@/common/generated-types/openapi.json';
import { authService } from '@/common/services/auth/auth.service';

// NOTE(ae): union type of all client names defined in ClientTypes. Add more client names by concatenating with a pipe (|).
type ClientName = 'userPreferencesClient' | 'userClient';

type ClientTypes = {
  userPreferencesClient: UserPreferencesClient;
  userClient: UserClient;
};

class OpenApiClientService {
  private apiInstance: OpenAPIClientAxios;

  constructor() {
    const definition = OPENAPI_DEFINITION;
    this.apiInstance = new OpenAPIClientAxios({ definition: definition as any });
    this.apiInstance.withServer(config.ENVIRONMENT);
  }

  public async init() {
    await this.apiInstance.init();
    this.apiInstance.instance.interceptors.response.use((response: any) => response, authService.responseErrorInterceptor.bind(authService));
    this.apiInstance.instance.interceptors.request.use(authService.jwtInterceptor.bind(authService), authService.errorInterceptor.bind(authService));
  }

  public async getClient<K extends ClientName>(): Promise<ClientTypes[K]> {
    return await this.apiInstance.getClient<ClientTypes[K]>();
  }
}

export const openApiClientService = new OpenApiClientService();
export const $api = async (param: ClientName) => await openApiClientService.getClient<typeof param>();
