const rootApiVersionUri = '/api/v1';
const legalCaseRootUri = `${rootApiVersionUri}/legalcases/{legalCaseId}`;
export const config = {
  PDFTRON_LICENSE:
    'legal-i AG (legal-i.ch):OEM:legal-i ::B+:AMS(20240831)::7AB57CD204F7780A0360B13AC9A2737860612FD5F70046AA54440D7BDFF5919642823DF5C7',
  PDFTRON_PATH: `${import.meta.env.BASE_URL}lib/10.7.3`,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT || 'local',
  APP_VERSION_URL: '/version.txt',
  APP_URL: import.meta.env.VITE_APP_URL,
  API: {
    HOST: import.meta.env.VITE_API_HOST || '',
    WS_HOST: `${import.meta.env.VITE_API_HOST}${rootApiVersionUri}/ws`,
    // Public Endpoints
    SHARING_URL: `${rootApiVersionUri}/sharing`,

    // Private Endpoints

    // Version
    VERSION: `${rootApiVersionUri}/version`,

    // Legalcases
    CASES_ENDPOINT: `${rootApiVersionUri}/legalcases`,
    CASES_UPDATES_ENDPOINT: `${rootApiVersionUri}/legalcases/updates`,
    CASE_METADATA_ENDPOINT: `${legalCaseRootUri}/metadata/{key}`,
    CASE_DIAGNOSIS_ENDPOINT: `${legalCaseRootUri}/diagnosis/`,

    CASES_ACCESS_ENDPOINT: {
      ROOT: `${legalCaseRootUri}/access`,
      DELETE: `${legalCaseRootUri}/access/{userId}`,
      USERS: `${legalCaseRootUri}/access/authorized-users`,
    },

    CASE_NOTES_ENDPOINT: {
      ROOT: `${legalCaseRootUri}/notebooks`,
      CRUD: `${legalCaseRootUri}/notebooks/{notebookId}`,
    },

    // Folders
    FOLDERS_ENDPOINT: `${rootApiVersionUri}/folders`,

    // Sourcefiles
    SOURCEFILE_ENDPOINT: `${legalCaseRootUri}/sourcefiles/{sourceFileId}`,
    SOURCEFILE_SEGMENTATION_ENDPOINT: `${legalCaseRootUri}/sourcefiles/{sourceFileId}/segmentation`,
    WORKBENCH_SEGMENTATION_ENDPOINT: `${rootApiVersionUri}/workbench/segmentation`,

    // Documents
    DOCUMENTS_ENDPOINT: `${legalCaseRootUri}/documents`,
    DOCUMENTS_METADATA: `${legalCaseRootUri}/documents/{documentId}/metadata`,
    DOCUMENTS_METADATA_BULK: `${legalCaseRootUri}/documents/metadata/bulk`,
    DOCUMENTS_CHAT: `${legalCaseRootUri}/documents/chat`,
    DOCUMENTS_METADATA_DETAILS: `${legalCaseRootUri}/documents/{documentId}/metadata/details`,
    DOCUMENTS_ENDPOINT_STATUS: `${legalCaseRootUri}/documents/{documentId}/status`,
    DOCUMENTS_UPDATE_READ: `${legalCaseRootUri}/documents/{documentId}/read`,
    DOCUMENTS_UPDATE_ALL_READ: `${legalCaseRootUri}/documents/all_read`,
    DOCUMENTS_READ_READ: `${legalCaseRootUri}/documents/read`,
    ANNOTATION_ENDPOINT: {
      ROOT: `${legalCaseRootUri}/annotations`,
    },
    LABELS_ENDPOINT: {
      ROOT: `${rootApiVersionUri}/labels`,
      LEGAL_CASE: `${legalCaseRootUri}/labels`,
      LEGAL_CASE_LABEL: `${legalCaseRootUri}/labels/{labelId}`,
      LEGAL_CASE_DOCUMENT_LABEL: `${legalCaseRootUri}/documents/{documentId}/labels/{labelId}`,
    },
    DUPLICATES_ENDPOINT: {
      BASE: `${legalCaseRootUri}/duplicate`,
      CANDIDATES: `${legalCaseRootUri}/duplicate/{originalDocumentId}/candidate`,
      REMOVE: `${legalCaseRootUri}/duplicate/{originalDocumentId}/{duplicateDocumentId}`,
    },
    MANUAL_SEGMENTATION: {
      SPLIT: `${legalCaseRootUri}/documents/{documentId}/segmentation/split`,
      MERGE: `${legalCaseRootUri}/documents/{documentId}/segmentation/merge`,
    },

    // Extracts
    DIAGNOSIS_EXTRACT_ENDPOINT: `${legalCaseRootUri}/extracts/diagnosis`,

    // Work inabilities
    WORKINABILITIES_ENDPOINT: `${legalCaseRootUri}/workinabilities/{id?}`,
    WORKINABILITIES_BULK_ENDPOINT: `${legalCaseRootUri}/workinabilities/bulk`,

    // Timelines
    TIMELINES_ENDPOINT: `${legalCaseRootUri}/timelines/{id?}`,

    // Files
    PRESIGNED_UPLOAD_ENDPOINT: `${rootApiVersionUri}/store/presigned-upload-uri`,
    FILE_STORE_ENDPOINT: `${rootApiVersionUri}/store/`,

    // Search
    SEARCH_ENDPOINT: {
      SEARCH_DOCUMENTS: `${rootApiVersionUri}/fulltextsearch/documents`,
      SEARCH_CASES: `${rootApiVersionUri}/fulltextsearch/legalcases`,
      SEARCH_SUGGESTIONS: `${rootApiVersionUri}/fulltextsearch/suggestions`,
    },

    // Similar cases
    SIMILAR_CASES: `${rootApiVersionUri}/similar-cases/{legalCaseId}`,

    // Explorer cases
    EXPLORER: `${rootApiVersionUri}/explorer/legalcases/{legalCaseId}`,

    TRANSFER_ENDPOINT: {
      BASE: `${rootApiVersionUri}/internal/transfer`,
      EXPORT: `${rootApiVersionUri}/internal/transfer/export`,
      IMPORT: `${rootApiVersionUri}/internal/transfer/import`,
      LIST: `${rootApiVersionUri}/internal/transfer/list`,
      PROGRESS: `${rootApiVersionUri}/internal/transfer/progress`,
    },

    // Export
    EXPORTS_ENDPOINT: {
      BASE: `${legalCaseRootUri}/exports`,
      CREATE: `${legalCaseRootUri}/exports`,
      CREATE_UPDATE: `${legalCaseRootUri}/exports/{exportId}`,
      DOCUMENTS: `${legalCaseRootUri}/exports/{exportId}/documents`,
      CONFIG: `${legalCaseRootUri}/exports/{exportId}/config`,
      PUBLISH: `${legalCaseRootUri}/exports/{exportId}/publish`,

      // PDF generation
      PREVIEW: `${legalCaseRootUri}/exports/{exportId}/preview`,

      // SHARING
      ACCESS: `${legalCaseRootUri}/exports/{exportId}/access`,

      // NOTIFY
      MAIL: `${legalCaseRootUri}/exports/{exportId}/email`,

      // HISTORY
      HISTORY: `${legalCaseRootUri}/exports/{exportId}/history`,
    },

    // Tickets
    LEGALCASE_TICKET_ENDPOINT: {
      ALL_TICKETS: `${rootApiVersionUri}/tickets`,
      CASE_TICKETS: `${legalCaseRootUri}/tickets`,
      TICKET: `${legalCaseRootUri}/tickets/{ticketId}`,
      EVENTS: `${legalCaseRootUri}/tickets/{ticketId}/events`,
      EVENT: `${legalCaseRootUri}/tickets/{ticketId}/events/{eventId}`,
      ATTACHMENT: `${legalCaseRootUri}/tickets/{ticketId}/fields/{fieldId}/attachments/{attachmentId}`,
      REQUEST_ACCESS: `${legalCaseRootUri}/tickets/{ticketId}/request-access`,
    },

    // Notifications
    NOTIFICATIONS_ENDPOINT: {
      ROOT: `${rootApiVersionUri}/notifications`,
    },

    // Pipeline
    PIPELINE: {
      RUN_SUPERVISOR: `${rootApiVersionUri}/internal/pipeline/runSupervisor`,
      REQUEST_TASK: `${rootApiVersionUri}/internal/pipeline/requestTask`,
      REQUEST_BULK_TASK: `${rootApiVersionUri}/internal/pipeline/requestBulkTask`,
      REQUEST_RESTART_TASK: `${rootApiVersionUri}/internal/pipeline/restart`,
      CANCEL_TASK: `${rootApiVersionUri}/internal/pipeline/cancelTask`,
      REVIEW_TASK_FINISHED: `${rootApiVersionUri}/internal/pipeline/reviewTaskFinished`,
      REQUEST_TENANT_TASKS: `${rootApiVersionUri}/internal/pipeline/requestTenantTasks`,
      AUDIT: `${rootApiVersionUri}/internal/pipeline/audit/{sourceFileId}`,
    },

    // Users
    AUTHORIZATION_ENDPOINT: {
      BASE: `${rootApiVersionUri}/auth/user`,
      SWITCH_ROLE: `${rootApiVersionUri}/auth/user/role`,
      LOGOUT: `${rootApiVersionUri}/auth/logout`,
      ORG: `${rootApiVersionUri}/org/{canonicalName}`,
    },

    // legal-i admin
    INTERNAL: {
      HOCR_EXPORT: `${rootApiVersionUri}/internal/hocr-export/{legalCaseId}/{sourceFileId}/{page}`,
      GOOGLE_DOCUMENTAI_EXPORT: `${rootApiVersionUri}/internal/google-documentai-export/{legalCaseId}/{sourceFileId}/{page}`,
      SEND_TEST_EMAIL: `${rootApiVersionUri}/internal/send-test-email`,
      CREATE_TEST_NOTIFICATION: `${rootApiVersionUri}/internal/create-test-notification`,
      CREATE_TEST_PIPELINE_NOTIFICATION: `${rootApiVersionUri}/internal/create-test-pipeline-notification`,
      CREATE_TEST_INACTIVE_CASES_NOTIFICATION: `${rootApiVersionUri}/internal/create-test-inactive-cases-notification`,
      CLEAR_CACHE: `${rootApiVersionUri}/internal/caches/evict`,
      CLEAR_COPILOT_HISTORIES: `${rootApiVersionUri}/internal/copilot/history`,
      CLEAR_COPILOT_ANSWER: `${rootApiVersionUri}/internal/legalcases/{legalCaseId}/copilot/history/catalog/{catalogKey}`,
    },

    // Tenant Page
    TENANT: {
      CREATE: `${rootApiVersionUri}/tenant`,
      RUD: `${rootApiVersionUri}/tenant/{id}`,

      CONFIG: `${rootApiVersionUri}/tenant/{id}/config`,
      ANNOTATIONS_CONFIG: `${rootApiVersionUri}/tenant/{id}/config/annotations`,
      TEMPLATES_CONFIG: `${rootApiVersionUri}/tenant/{id}/config/templates`,
      AGENT_HEARTBEAT: `${rootApiVersionUri}/tenant/agent-heartbeat`,
      AGENT_EVENTS: `${rootApiVersionUri}/tenant/agent-events`,
      SEND_TEST_EVENT: `${rootApiVersionUri}/tenant/agent-events`,
      DELETE_EVENTS: `${rootApiVersionUri}/tenant/agent-events`,
      AUDITS: `${rootApiVersionUri}/tenant/audits`,
      CACHE: `${rootApiVersionUri}/tenant/{id}/cache`,
    },
    ORGANIZATION: {
      ORGANIZATIONS: `${rootApiVersionUri}/orgs`,
      TENANTS: `${rootApiVersionUri}/orgs/{id}/tenants`,
      CREDENTIALS: `${rootApiVersionUri}/orgs/{id}/credentials`,
      CONNECTIONS: `${rootApiVersionUri}/orgs/{id}/connections`,
      GROUPS: `${rootApiVersionUri}/orgs/{id}/groups`,
      AGENT_CREDENTIALS_BY_TENANTID: `${rootApiVersionUri}/orgs/{id}/agent/credentials/byTenantId/{tenantId}`,
      AGENT_ROTATE_SECRET: `${rootApiVersionUri}/orgs/{id}/agent/{clientId}/rotate-secret`,
    },

    // Reporting
    REPORTS: `${rootApiVersionUri}/reports`,
    LEGAL_CASE_REAL_TIME_REPORTING: `${rootApiVersionUri}/reports/realTime/legalCase/{range}`,
    // Billing
    BILLING: `${rootApiVersionUri}/billing-reports`,

    // Data labeling
    DATA_LABELING: {
      FREEZE_DM: `${rootApiVersionUri}/internal/freeze/{tenantId}/document`,
      UNFREEZE_DM_INCLUDING_LABELED: `${rootApiVersionUri}/internal/unfreeze/{tenantId}/document/{dmKey}?includeUserLabeled=true`,
      UNFREEZE_DM_EXCLUDING_LABELED: `${rootApiVersionUri}/internal/unfreeze/{tenantId}/document/{dmKey}?includeUserLabeled=false`,
      FREEZE_LCM: `${rootApiVersionUri}/internal/freeze/{tenantId}/legalcase`,
      UNFREEZE_LCM_INCLUDING_LABELED: `${rootApiVersionUri}/internal/unfreeze/{tenantId}/legalcase/{lcmKey}?includeUserLabeled=true`,
      UNFREEZE_LCM_EXCLUDING_LABELED: `${rootApiVersionUri}/internal/unfreeze/{tenantId}/legalcase/{lcmKey}?includeUserLabeled=false`,
      FREEZE_OTH: `${rootApiVersionUri}/internal/freeze/{tenantId}/other`,
      UNFREEZE_OTH_INCLUDING_LABELED: `${rootApiVersionUri}/internal/unfreeze/{tenantId}/other/{othKey}?includeUserLabeled=true`,
      UNFREEZE_OTH_EXCLUDING_LABELED: `${rootApiVersionUri}/internal/unfreeze/{tenantId}/other/{othKey}?includeUserLabeled=false`,
    },

    // Copilot
    COPILOT: {
      DOCUMENT: {
        BASE: `${legalCaseRootUri}/documents/{documentId}/copilot`,
        HISTORY: `${legalCaseRootUri}/documents/{documentId}/copilot/history`,
        FEEDBACK: `${legalCaseRootUri}/documents/{documentId}/copilot/feedback`,
      },
      LEGALCASE: {
        BASE: `${legalCaseRootUri}/copilot`,
        HISTORY: `${legalCaseRootUri}/copilot/history`,
        FEEDBACK: `${legalCaseRootUri}/copilot/feedback`,
      },
      PLAYGROUND: `${legalCaseRootUri}/copilot/playground`,
      CATALOGKEY_HISTORY: `${legalCaseRootUri}/copilot/history/catalog/{catalogKey}`,
      CATALOG: {
        LIST: `${rootApiVersionUri}/copilot/catalogs`,
        UPDATE: `${rootApiVersionUri}/copilot/catalogs/{scope}`,
      },
      DASHBOARD: {
        LIST: `${rootApiVersionUri}/copilot/dashboards`,
        LIST_KEYS: `${rootApiVersionUri}/copilot/dashboard-keys`,
        UPDATE: `${rootApiVersionUri}/copilot/dashboards/{dashboardKey}`,
        TRANSLATE: `${rootApiVersionUri}/copilot/dashboards/internal/translate`,
        GENERATE_PROMPT: `${rootApiVersionUri}/copilot/dashboards/internal/generate-prompt`,
      },
      CONTEXT_STRATEGIES: `${rootApiVersionUri}/copilot/context-strategies`,
      AGENTS: `${rootApiVersionUri}/copilot/agents`,
    },

    // Consistency
    CONSISTENCY: {
      ERRORS: `${rootApiVersionUri}/consistency/errors`,
      REGENERATE: `${rootApiVersionUri}/consistency/reports/regenerate`,
      REPORTS: `${rootApiVersionUri}/consistency/reports`,
      CHECK_STATUS: `${rootApiVersionUri}/consistency/reports/status`,
      REPAIR_STATUS: `${rootApiVersionUri}/consistency/reports/repair-status`,
    },
  },
  AUTH: {
    DOMAIN: import.meta.env.VITE_AUTH_DOMAIN || 'legal-i.eu.auth0.com',
    CLIENT_ID: import.meta.env.VITE_AUTH_CLIENT_ID || '',
    AUDIENCE: import.meta.env.VITE_AUTH_AUDIENCE || '',
  },
  NOTIFICATION: {
    POLLING_RATE: import.meta.env.VITE_NOTIFICATION_POLLING_RATE || 5 * 60 * 1000,
  },

  // Non-functionals, analytics, support, errors, etc
  SENTRY: {
    ENABLED: import.meta.env.VITE_SENTRY_ENABLED === 'true' || false,
    DSN: 'https://4958017062244ba2e2e9b2687629dd84@sentry.legal-i.ch/4508223392120832',
  },
  MIXPANEL: {
    ENABLED: import.meta.env.VITE_MIXPANEL_ENABLED === 'true' || false,
    TOKEN: import.meta.env.VITE_MIXPANEL_TOKEN || false,
  },
  HOTJAR: {
    ENABLED: import.meta.env.VITE_HOTJAR_ENABLED === 'true' || false,
    SITE_ID: import.meta.env.VITE_HOTJAR_SITE_ID || '',
    DEBUG: import.meta.env.VITE_HOTJAR_DEBUG === 'true' || false,
  },
  INTERCOM: {
    ENABLED: import.meta.env.VITE_INTERCOM_ENABLED === 'true' || false,
    APP_ID: 'bijmrxn5',
  },

  SUPPORTED_BROWSERS: import.meta.env.VITE_SUPPORTED_BROWSERS ? import.meta.env.VITE_SUPPORTED_BROWSERS.split(',').map((e) => e.trim()) : [],
  BLOCKED_OS: import.meta.env.VITE_BLOCKED_OS ? import.meta.env.VITE_BLOCKED_OS.split(',').map((e) => e.trim()) : [],
  LOGGING: import.meta.env.VITE_LOGGING_ENABLED === 'true' || false,
  VERSION: import.meta.env.VITE_APP_VERSION ? import.meta.env.VITE_APP_VERSION : 'Development Build',
};
