import axios, { AxiosError } from 'axios';

import { handleError } from '@/app/components/errors/services/errorhandler.service';
import { config } from '@/app/config';
import { $t } from '@/app/i18n/i18n.service';
import appService from '@/app/services/app.service';
import { ticketClient } from '@/case-detail/subviews/collaboration/services/ticket.client';
import { authService } from '@/common/services/auth/auth.service';
import userService from '@/common/services/users/user.service';
import { API } from '@/common/types/api.types';
import { UUID } from '@/common/types/common.types';

export const legalCaseAPIClient = {
  async create(caseData: API.LegalCase.CreateUpdateRequest) {
    try {
      const response = await axios.post(config.API.CASES_ENDPOINT, caseData);
      return response.data as UUID;
    } catch (error) {
      handleError($t('CaseList.caseCannotBeAdded'), error);
      return null;
    }
  },

  async fetchList(): Promise<API.LegalCase.ListResponse[]> {
    try {
      const response = await axios.get(`${config.API.CASES_ENDPOINT}`);
      return response.data;
    } catch (e) {
      handleError($t('CaseList.casesCannotBeLoaded'), e);
      throw e;
    }
  },

  async fetchListResponse(legalCaseId: UUID, options?: { signal?: AbortSignal }): Promise<API.LegalCase.ListResponse | null> {
    try {
      const response = await axios.get(`${config.API.CASES_ENDPOINT}/${legalCaseId}/listresponse`, {
        signal: options?.signal, // Pass the AbortSignal to axios
      });
      return response.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        // if request has been canceled return null
        return null;
      }
      // Handle any other errors
      handleError($t('CaseList.casesCannotBeLoaded'), error);
      throw error;
    }
  },
  async fetch(legalCaseId: UUID): Promise<API.LegalCase.Response | null> {
    try {
      const response = await axios.get(`${config.API.CASES_ENDPOINT}/${legalCaseId}`);
      if (!(response?.data?.ready || authService.hasPermission('LEGALI_ADMIN'))) {
        appService.appError('CASE_PROCESSING_ERROR');
        return null;
      }
      return response.data;
    } catch (e) {
      handleError($t('CaseList.caseCannotBeLoaded'), e, true);
      switch ((e as AxiosError)?.response?.status) {
        case 404:
          if (window.location.search.includes('ticketId')) {
            const ticketId = new URLSearchParams(window.location.search).get('ticketId');
            const ticket = (await ticketClient.loadWorkspaceTickets()).find((t) => t.id === ticketId);
            // if there is a ticketId in the URL, check if the user is the coordinator or the recipient of the ticket
            if (authService.state.userId && ticket) {
              const user = userService.getUser(authService.state.userId);
              if (
                user &&
                ticket.id === ticketId &&
                (ticket.recipient === authService.state.userId ||
                  ticket.coordinator === authService.state.userId ||
                  ticket.coordinator === userService.getOrganisationUserId(user.homeTenantId!))
              ) {
                appService.appError('ACCESS_REVOKED');
                return null;
              }
            }
          }
          appService.appError('CASE_NOT_FOUND_ERROR');
          break;
        default:
          appService.appError('CASE_LOAD_ERROR');
      }
      return null;
    }
  },

  async fetchMetadata(legalCaseId: UUID, key: API.LegalCase.PiiKey) {
    try {
      const response = await axios.get(config.API.CASE_METADATA_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('{key}', key));
      return response.data;
    } catch (e) {
      handleError($t('CaseList.caseDataCannotBeLoaded'), e);
      return [];
    }
  },

  async update(legalCaseId: UUID, caseData: API.LegalCase.CreateUpdateRequest): Promise<API.LegalCase.Response | null> {
    try {
      return await axios.patch(`${config.API.CASES_ENDPOINT}/${legalCaseId}`, caseData);
    } catch (error) {
      handleError($t('CaseList.caseCannotBeProcessed'), error);
      return null;
    }
  },

  async updateMetadata(legalCaseId: UUID, key: API.LegalCase.PiiKey, value: string) {
    try {
      await axios.post(config.API.CASE_METADATA_ENDPOINT.replace('{legalCaseId}', legalCaseId).replace('{key}', key), { updatedValue: value });
      return true;
    } catch (error) {
      handleError($t('CaseList.caseDataCannotBeSaved'), error);
      return false;
    }
  },

  async updateStatus(legalCaseId: UUID, newStatus: API.LegalCase.LegalCaseStatus): Promise<API.LegalCase.Response | null> {
    try {
      return await axios.patch(`${config.API.CASES_ENDPOINT}/${legalCaseId}/status/${newStatus}`);
    } catch (error) {
      handleError($t('CaseList.statusCannotBeChanged'), error);
      return null;
    }
  },

  async delete(legalCaseId: UUID) {
    try {
      await axios.delete(`${config.API.CASES_ENDPOINT}/${legalCaseId}`);
      return true;
    } catch (e) {
      handleError($t('CaseList.caseCannotBeDeleted'), e);
      return false;
    }
  },
};
