<template>
  <div class="pii-wrapper bg-surface">
    <div class="content-container">
      <div class="d-flex column-container">
        <template v-for="(column, index) in columns" :key="index">
          <div v-if="hasVisibleFields(column.fields)" class="pii-column">
            <div class="column-title text-subtitle-2 mb-2">{{ $t(column.title) }}</div>
            <div class="pii-chips">
              <template v-for="field in column.fields" :key="field.key">
                <PiiQuickAccessChip v-if="shouldShowField(field)" :value="getFieldValue(field)" :label="getFieldLabel(field)" />
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="d-flex justify-end actions">
      <v-btn variant="text" density="comfortable" class="mr-2 text-subtitle-2" @click.stop="expanded = !expanded">
        {{ $t(expanded ? 'App.Bar.CaseDetailIndicator.hideAll' : 'App.Bar.CaseDetailIndicator.showAll') }}
      </v-btn>
      <v-btn variant="text" density="comfortable" class="text-subtitle-2" @click.stop="handleEdit">
        {{ $t('App.Bar.CaseDetailIndicator.edit') }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import PiiQuickAccessChip from '@/case-detail/app-bar/PiiQuickAccessChip.vue';
import detailViewService from '@/case-detail/services/detail.view.service';
import { formatToLocale } from '@/common/services/date.utils';
import entityService, { CaseMetadataKey } from '@/common/services/entity.service';
import { API } from '@/common/types/api.types';

type PiiKey = (typeof API.LegalCase.PII_KEY)[keyof typeof API.LegalCase.PII_KEY];
type CompositeKey = 'FULL_NAME' | 'REFERENCE' | 'ADDRESS_FULL';

interface PiiData {
  value: string;
}

interface PiiField {
  key: PiiKey | CompositeKey;
  label?: string;
  date?: boolean;
  composite?: PiiKey[];
}

interface Column {
  title: string;
  fields: PiiField[];
  expandedFields: PiiField[];
}

export default defineComponent({
  components: { PiiQuickAccessChip },

  data() {
    return {
      expanded: false,
      caseMetadata: entityService.LEGALCASE_METADATA,
      minimalColumns: [
        {
          title: 'App.Bar.CaseDetailIndicator.personalDetails',
          fields: [
            {
              key: 'FULL_NAME',
              label: this.$t('App.Bar.CaseDetailIndicator.nameFieldLabel'),
              composite: [API.LegalCase.PII_KEY.PII_FIRSTNAME, API.LegalCase.PII_KEY.PII_LASTNAME],
            },
            {
              key: 'REFERENCE',
              label: this.$t('App.Bar.CaseDetailIndicator.referenceFieldLabel'),
            },
            { key: API.LegalCase.PII_KEY.CUSTOM_1 },
            {
              key: API.LegalCase.PII_KEY.PII_BIRTHDATE,
              date: true,
            },
            { key: API.LegalCase.PII_KEY.PII_AHV_NR },
            {
              key: 'ADDRESS_FULL',
              label: this.$t('App.Bar.CaseDetailIndicator.addressFieldLabel'),
              composite: [API.LegalCase.PII_KEY.PII_ADDRESS, API.LegalCase.PII_KEY.PII_PLZ, API.LegalCase.PII_KEY.PII_CITY],
            },
          ],
          expandedFields: [
            { key: API.LegalCase.PII_KEY.PII_GENDER },
            { key: API.LegalCase.PII_KEY.PII_NATIONALITY },
            { key: API.LegalCase.PII_KEY.PII_MARITAL_STATUS },
            { key: API.LegalCase.PII_KEY.PII_PHONE_NO },
          ],
        },
        {
          title: 'App.Bar.CaseDetailIndicator.incident',
          fields: [
            { key: API.LegalCase.PII_KEY.PII_COMPANY },
            {
              key: API.LegalCase.PII_KEY.INCIDENT_DATE,
              date: true,
            },
            { key: API.LegalCase.PII_KEY.INCIDENT_DESCRIPTION },
          ],
          expandedFields: [
            { key: API.LegalCase.PII_KEY.INCIDENT_ICD10_CODE },
            { key: API.LegalCase.PII_KEY.INCIDENT_KIND },
            {
              key: API.LegalCase.PII_KEY.INCIDENT_LAST_WORKING_DAY,
              date: true,
            },
            { key: API.LegalCase.PII_KEY.INCIDENT_TYPE_REASON },
            { key: API.LegalCase.PII_KEY.INCIDENT_CONDITION_DESCRIPTION },
          ],
        },
        {
          title: 'App.Bar.CaseDetailIndicator.occupation',
          fields: [{ key: API.LegalCase.PII_KEY.JOB_ROLE }, { key: API.LegalCase.PII_KEY.JOB_TITLE }],
          expandedFields: [
            { key: API.LegalCase.PII_KEY.JOB_ISCO_CODE },
            { key: API.LegalCase.PII_KEY.JOB_POSITION },
            { key: API.LegalCase.PII_KEY.JOB_COMPETENCE_LEVEL },
            { key: API.LegalCase.PII_KEY.JOB_PHYSICAL_LOAD },
            { key: API.LegalCase.PII_KEY.JOB_TIME_PERCENTAGE },
            { key: API.LegalCase.PII_KEY.JOB_INCOME_YEAR },
            { key: API.LegalCase.PII_KEY.JOB_INCOME_MONTHLY },
            { key: API.LegalCase.PII_KEY.JOB_INCOME_HOURLY },
            {
              key: API.LegalCase.PII_KEY.JOB_START_DATE,
              date: true,
            },
            {
              key: API.LegalCase.PII_KEY.JOB_END_DATE,
              date: true,
            },
            { key: API.LegalCase.PII_KEY.JOB_FULL_TIME_HOURS },
            { key: API.LegalCase.PII_KEY.JOB_EMPLOYER },
            { key: API.LegalCase.PII_KEY.JOB_EMPLOYER_ADDRESS },
            { key: API.LegalCase.PII_KEY.JOB_ROLE_WORKPLACE },
            { key: API.LegalCase.PII_KEY.JOB_TIME_COMMITMENT },
            { key: API.LegalCase.PII_KEY.JOB_TERMINATION },
            { key: API.LegalCase.PII_KEY.JOB_CONTRACT_DURATION },
          ],
        },
      ] as Column[],
    };
  },

  computed: {
    columns(): Column[] {
      return this.minimalColumns.map((column) => ({
        ...column,
        fields: this.expanded ? [...column.fields, ...column.expandedFields] : column.fields,
      }));
    },
    currentCase() {
      return detailViewService.getCurrentLegalCase()!;
    },
    currentCasePii() {
      return this.currentCase.pii as Record<PiiKey, PiiData>;
    },
  },

  methods: {
    handleEdit() {
      detailViewService.openPanel('PII');
    },

    hasVisibleFields(fields: PiiField[]) {
      return fields.some((field) => this.shouldShowField(field));
    },

    isCompositeKey(key: PiiKey | CompositeKey): key is CompositeKey {
      return ['FULL_NAME', 'REFERENCE', 'ADDRESS_FULL'].includes(key);
    },

    shouldShowField(field: PiiField) {
      if (field.composite) {
        return field.composite.some((key) => this.currentCasePii[key]?.value);
      }
      if (this.isCompositeKey(field.key)) {
        return true;
      }
      return this.currentCasePii[field.key]?.value;
    },

    getFieldLabel(field: PiiField): string {
      if (this.isCompositeKey(field.key)) {
        return field.label || '';
      }
      return field.label || this.caseMetadata[field.key as CaseMetadataKey]?.title || '';
    },

    getFieldValue(field: PiiField): string {
      if (field.composite) {
        if (field.key === 'FULL_NAME') {
          return field.composite
            .map((key) => this.currentCasePii[key]?.value)
            .filter((value) => value)
            .join(' ');
        }
        return field.composite
          .map((key) => this.currentCasePii[key]?.value)
          .filter((value) => value)
          .join(', ');
      }

      if (this.isCompositeKey(field.key)) {
        return '';
      }

      const value = this.currentCasePii[field.key]?.value;

      if (field.date && value) {
        return formatToLocale(value);
      }

      const metadata = this.caseMetadata[field.key as CaseMetadataKey];
      if (metadata?.options && value) {
        return metadata.options[value]?.title || value;
      }

      return value || '';
    },
  },
});
</script>

<style scoped>
.pii-wrapper {
  min-width: 400px;
  margin-left: -1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 2px solid rgba(var(--v-theme-outline));
  display: flex;
  flex-direction: column;
}

.content-container {
  padding: 10px 16px 16px 16px;
  flex-grow: 1;
}

.column-container {
  gap: 16px;
}

.pii-column {
  min-width: 120px;
  max-width: 200px;
}

.column-title {
  color: rgba(var(--v-theme-on-surface), 0.7);
  font-weight: 500;
}

.pii-chips {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: max-height 0.3s ease-in-out;
}

.actions {
  border-top: 1px solid rgba(var(--v-theme-on-surface), 0.12);
  padding: 8px 16px;
  margin-top: auto;
}
</style>
