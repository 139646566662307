import * as annotation from '@/common/types/api-types/annotation.api.types';
import * as auth from '@/common/types/api-types/auth.api.types';
import * as copilot from '@/common/types/api-types/copilot.api.types';
import * as diagnosis from '@/common/types/api-types/diagnosis.api.types';
import * as document from '@/common/types/api-types/document.api.types';
import * as general from '@/common/types/api-types/general.api.types';
import * as label from '@/common/types/api-types/label.api.types';
import * as legalCase from '@/common/types/api-types/legalcase.api.types';
import * as notebook from '@/common/types/api-types/notebook.api.types';
import * as sourceFile from '@/common/types/api-types/sourcefile.api.types';
import * as ticket from '@/common/types/api-types/ticket.api.types';
import * as timeline from '@/common/types/api-types/timeline.api.types';
import * as user from '@/common/types/api-types/user.api.types';
import * as workInability from '@/common/types/api-types/workinability.api.types';

// NOTE(dp): in this particular case namespaces very convenient to distinguish API object from internal modifed objects of same kind
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace API {
  export import General = general;
  export import Auth = auth;
  export import User = user;
  export import Document = document;
  export import Diagnosis = diagnosis;
  export import Label = label;
  export import LegalCase = legalCase;
  export import SourceFile = sourceFile;
  export import Annotation = annotation;
  export import Ticket = ticket;
  export import Notebook = notebook;
  export import WorkInability = workInability;
  export import Copilot = copilot;
  export import Timeline = timeline;
}
