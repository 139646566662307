<template>
  <v-chip
    v-if="!!value"
    v-tippy="$t('App.Bar.CaseDetailIndicator.copyTooltip', [label])"
    class="mr-1 quick-access-chip"
    color="gray"
    variant="outlined"
    size="small"
    label
    @click="copy()"
  >
    <div class="chip-content">
      <div class="text-truncate chip-quick-access">
        {{ value }}
      </div>
      <v-icon size="small" class="copy-icon"> mdi-content-copy </v-icon>
    </div>
  </v-chip>
</template>

<script>
import { copyToClipboard } from '@/common/services/common.utils';

export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  methods: {
    copy() {
      copyToClipboard(this.value);
      this.$a.l(this.$a.e.CASE_PIIQUICKACCESS_COPY, { label: this.label });
    },
  },
};
</script>

<style scoped>
.quick-access-chip {
  width: 100%;
}

.quick-access-chip :deep(.v-chip__content) {
  width: 100%;
}

.chip-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chip-quick-access {
  min-width: 0;
}

.copy-icon {
  margin-left: auto;
  flex-shrink: 0;
}
</style>
