<template>
  <div class="wrapper px-2">
    <l-menu v-model="active" :close-on-content-click="false" class="search-menu-no-box-shadow" disable-keys>
      <template #activator="{ props }">
        <v-text-field
          ref="input"
          v-model="query"
          density="compact"
          :disabled="isDisabled"
          :placeholder="$i18n.t('App.Bar.ExplorerSearch.searchAllCases')"
          autocomplete="off"
          class="search-text-field rounded-xl"
          clearable
          variant="solo-filled"
          flat
          hide-details="auto"
          maxlength="100"
          persistent-placeholder
          prepend-inner-icon="mdi-magnify"
          :loading="loading"
          v-bind="props"
          @update:model-value="active = true"
          @click:prepend-inner="onSuggestionSelected($event.target.value, true)"
          @click:clear="onClear()"
          @keydown.stop="onKeydown($event)"
        />
      </template>

      <!-- content -->
      <v-card class="card" flat>
        <div v-show="view === 'default'" class="suggestions-default">
          <v-list-item v-if="historyComputed.length === 0">
            <v-list-item-subtitle>
              {{ $t('App.Bar.ExplorerSearch.startTypingToViewSuggestions') }}
            </v-list-item-subtitle>
          </v-list-item>
          <Suggestions
            v-else
            key="historySuggestions"
            v-model:selected="selected"
            :suggestions="historyComputed"
            :header="$t('Common.recentlySearched')"
            icon="mdi-history"
            removable
            @click:remove="removeFromHistory($event.suggestion)"
            @click:suggestion="search($event)"
          />
        </div>

        <!-- query-based suggestions -->
        <div v-show="view === 'suggestions'" class="suggestions-query">
          <Suggestions
            key="authorsSuggestions"
            v-model:selected="selected"
            :suggestions="authors"
            icon="mdi-account-outline"
            :header="$t('App.Bar.ExplorerSearch.authors')"
            @click:suggestion="onSuggestionSelected($event)"
          />

          <Suggestions
            key="labelSuggestions"
            v-model:selected="selected"
            :suggestions="labels"
            icon="mdi-label"
            :header="$t('App.Bar.ExplorerSearch.labels')"
            @click:suggestion="onSuggestionSelected($event)"
          />

          <Suggestions
            key="diagnosisSuggestions"
            v-model:query="query"
            v-model:selected="selected"
            :suggestions="diagnosis"
            :header="$t('App.Bar.ExplorerSearch.diagnosis')"
            icon="mdi-stethoscope"
            icon-color="primary"
            @click:suggestion="onSuggestionSelected($event)"
          />

          <Suggestions
            key="fulltextSuggestions"
            v-model:query="query"
            v-model:selected="selected"
            :suggestions="fulltext"
            :header="$t('App.Bar.ExplorerSearch.fullText')"
            icon="mdi-magnify"
            @click:suggestion="onSuggestionSelected($event)"
          />
        </div>

        <div v-show="view === 'no-suggestions'" class="suggestions-query">
          <v-list-item>
            <v-list-item-subtitle class="text-wrap">
              {{ $t('App.Bar.ExplorerSearch.noResultsForQuery', [query]) }}
            </v-list-item-subtitle>
          </v-list-item>
        </div>

        <v-divider />

        <v-list-item v-show="view === 'suggestions'" density="compact">
          <template #prepend>
            <v-icon>mdi-keyboard-return</v-icon>
          </template>

          <v-list-item-subtitle>
            {{ onEnterText }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-card>
    </l-menu>
  </div>
</template>

<script>
import { debounce } from 'lodash';

import { mapCaseSearchSuggestions } from '@/case-detail/search/services/suggestions.helper';
import icd10Service from '@/case-detail/subviews/diagnosis/services/icd10.service';
import Suggestions from '@/case-explorer/components/ExplorerSearchInputSuggestions.vue';
import explorerService from '@/case-explorer/services/explorer.service';
import { broadcastEventBus } from '@/common/services/broadcast.service';

export default {
  components: { Suggestions },
  data() {
    return {
      isInitialized: false,
      loading: false,
      query: null,
      active: false,
      selected: null,
      selectedIndex: -1,
      // data
      aggregatedDiagnoses: [],
      // suggestions
      suggestionsPromises: [],
      history: [],
      fulltext: [],
      labels: [],
      diagnosis: [],
      authors: [],
    };
  },
  computed: {
    view() {
      if (!this.active || this.loading) {
        return null;
      }
      if (!this.query) {
        return 'default';
      }
      if (this.query && this.allSuggestions.length > 0) {
        return 'suggestions';
      }
      if (this.query && this.allSuggestions.length === 0) {
        return 'no-suggestions';
      }
      return null;
    },
    onEnterText() {
      return !!this.selected && !!this.all[this.selectedIndex]
        ? this.$t('App.Bar.ExplorerSearch.showResultsForItemWithEnterShortcut', { item: this.all[this.selectedIndex].suggestion })
        : this.$t('App.Bar.ExplorerSearch.showAllResultsWithEnterShortcut');
    },
    historyComputed() {
      return this.history.map((h) => ({
        id: `HISTORY__${h}`,
        suggestion: h,
        field: 'fulltext',
      }));
    },
    allSuggestions() {
      return [...this.authors, ...this.labels, ...this.diagnosis, ...this.fulltext];
    },
    all() {
      switch (this.view) {
        case 'default':
          return [...this.historyComputed];
        case 'suggestions':
          return this.allSuggestions;
        case 'no-suggestions':
          return [];
        default:
          return [];
      }
    },
    isDisabled() {
      return this.isInitialized && explorerService.state.isLoading;
    },
    cases() {
      return explorerService.getExploredCases();
    },
  },
  watch: {
    async active() {
      if (!this.isInitialized) {
        this.loading = true;
        await explorerService.init();
        this.aggregateDiagnoses(this.cases);
        this.isInitialized = true;
        this.active = true;
        this.loading = false;
      }
    },
    view() {
      this.resetSelections();
    },
    query(query) {
      this.resetSelections();

      if (!this.isValid(query)) {
        this.resetSuggestions();
        return;
      }

      // debounced request
      this.loading = true;
      if (this.debSearch) {
        this.debSearch.cancel();
      }
      // get last token from query
      const queryTokens = query.split(/\s+/);
      const lastQueryToken = queryTokens[queryTokens.length - 1];

      // Note(ndv): maybe we simplify this, define the suggested fields somewhere, dynamically define data props etc.
      this.debSearch = debounce(this.fetchAllSuggestions, 200);
      this.debSearch(lastQueryToken);
    },
    cases(cases) {
      this.aggregateDiagnoses(cases);
    },
  },
  async created() {
    // load history if existing
    const key = 'CASE_SEARCH_HISTORY';
    if (localStorage.getItem(key) !== null) {
      this.history = JSON.parse(localStorage.getItem(key));
    }

    broadcastEventBus.subscribe('SEARCH_RESET_EVENT', this.handleSearchResetEvent);
    broadcastEventBus.subscribe('UPDATE_SMART_SEARCH_QUERY_EVENT', this.handleUpdateSmartSearchQueryEvent);
  },
  beforeUnmount() {
    broadcastEventBus.unsubscribe('SEARCH_RESET_EVENT', this.handleSearchResetEvent);
    broadcastEventBus.unsubscribe('UPDATE_SMART_SEARCH_QUERY_EVENT', this.handleUpdateSmartSearchQueryEvent);
  },
  methods: {
    onSuggestionSelected(suggestion, onEnter = false) {
      // compute value
      let sugg = suggestion;
      if (onEnter && this.selectedIndex > -1) {
        sugg = this.all[this.selectedIndex];
      } else if (onEnter) {
        sugg = {
          suggestion: suggestion.trim(),
          field: 'fulltext',
        };
      }

      // no empty query search if required
      if (sugg.suggestion.length === 0) {
        return;
      }

      // update input value
      this.query = null;
      // update history if necessary
      if (sugg.field === 'fulltext') {
        this.updateHistory(sugg.suggestion);
      }

      this.active = false;
      // emit event
      this.search(sugg);

      // reset selections
      this.resetSelections();
    },
    search(suggestion) {
      switch (suggestion.field) {
        case 'fulltext': {
          explorerService.fullTextSearch(suggestion.suggestion);
          break;
        }
        case 'diagnosis': {
          explorerService.diagnosisSearch(suggestion.value);
          break;
        }
        case 'labels': {
          explorerService.labelsSearch(suggestion.value);
          break;
        }
        case 'authors': {
          explorerService.authorsSearch(suggestion.value);
          break;
        }
        default: {
          // Nothing to do, ignore.
        }
      }
    },
    async fetchSuggestions(query, fieldKey) {
      switch (fieldKey) {
        case 'fulltext': {
          return mapCaseSearchSuggestions((await explorerService.getFullTextSuggestions(query)).data, 10);
        }
        case 'diagnosis': {
          return explorerService.getDiagnosisSuggestions(this.aggregatedDiagnoses, query, 5);
        }
        case 'labels': {
          return explorerService.getLabelsSuggestions(query, 5);
        }
        case 'authors': {
          return explorerService.getAuthorsSuggestions(query, 5);
        }
        default: {
          // Nothing to do, ignore.
          return [];
        }
      }
    },
    fetchAllSuggestions(searchQuery) {
      // init suggestions fetching promises
      const keys = ['fulltext', 'labels', 'diagnosis', 'authors'];

      const promises = [];
      for (const key of keys) {
        promises.push(
          new Promise((resolve) => {
            this.fetchSuggestions(searchQuery, key).then((result) => resolve((this[key] = result)));
          }),
        );
      }

      Promise.all(promises).then(() => (this.loading = false));
    },
    aggregateDiagnoses(cases) {
      const m = new Map();
      const caseDiagnoses = cases.flatMap((d) => d.diagnoses);
      for (const diagnosis of caseDiagnoses) {
        // sanity check
        if (!diagnosis?.qualifications || !diagnosis.relevant || !icd10Service.isPresent(diagnosis.code)) continue;

        // aggregation
        if (m.has(diagnosis.code)) {
          const s = new Set([...m.get(diagnosis.code).qualifications, ...Object.keys(diagnosis.qualifications)]);

          m.set(diagnosis.code, {
            ...diagnosis,
            qualifications: [...s],
          });
        } else {
          m.set(diagnosis.code, {
            ...diagnosis,
            qualifications: [...Object.keys(diagnosis.qualifications)],
          });
        }
      }
      this.aggregatedDiagnoses = [...m.values()];
    },
    onClear() {
      this.resetSelections();
      this.resetSuggestions();
      explorerService.clearSearchTerms();
    },
    onKeydown(event) {
      if (event.code === 'Enter') {
        this.onSuggestionSelected(event.target.value, true);
      } else if (event.code === 'Escape') {
        this.active = false;
      } else if (event.code === 'ArrowUp') {
        this.prev();
      } else if (event.code === 'ArrowDown') {
        this.next();
      }
    },
    prev() {
      const prevIndex = this.selectedIndex - 1 < 0 ? this.all.length - 1 : this.selectedIndex - 1;
      this.selected = this.all[prevIndex].id;
      this.selectedIndex = prevIndex;
    },
    next() {
      const nextIndex = this.selectedIndex + 1 >= this.all.length ? 0 : this.selectedIndex + 1;
      this.selected = this.all[nextIndex].id;
      this.selectedIndex = nextIndex;
    },
    updateHistory(query) {
      // only the latest 3 queries are kept (and only from full-text searches)
      if (this.history.includes(query) || !this.isValid(query)) return;
      const h = [...this.history];
      h.unshift(query);
      // update local state
      this.history = h.slice(0, Math.min(3, h.length));
      // update localstorage
      localStorage.setItem('searchHistory', JSON.stringify(this.history));
    },
    removeFromHistory(query) {
      // update local state
      this.history = this.history.filter((q) => q !== query);
      // update localstorage
      localStorage.setItem('searchHistory', JSON.stringify(this.history));
    },
    resetSelections() {
      // Note(ndv): without $nextTick (or delay) the selected value is never set, because it is cleared too fast
      this.$nextTick(() => {
        this.selected = null;
        this.selectedIndex = -1;
      });
    },
    resetSuggestions() {
      this.labels = [];
      this.diagnosis = [];
      this.fulltext = [];
      this.authors = [];
    },
    reset() {
      if (this.query) {
        this.query = null;
      }
      this.resetSuggestions();
    },
    isValid(query) {
      return !!query;
    },
    handleSearchResetEvent() {
      this.reset();
    },
    handleUpdateSmartSearchQueryEvent(event) {
      this.reset();
      this.query = event.query;
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  max-width: 600px;
}
.card {
  height: auto;
  background: rgb(var(--v-theme-surface));
}

.suggestions-default,
.suggestions-query {
  height: auto;
  background: rgb(var(--v-theme-surface));
}

.search-text-field :deep(.v-input__slot::before) {
  /* border: none; */
}

.search-text-field :deep(input) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-menu-no-box-shadow :deep(.v-menu__content) {
  box-shadow: none !important;
  border: none !important;
}

.text-wrap {
  word-break: break-all;
}
</style>
