import { ObjectValues, UUID } from '@/common/types/common.types';

export const STATUS = {
  ACTIVE: 'ACTIVE',
  DELETED: 'DELETED',
} as const;

export type Status = ObjectValues<typeof STATUS>;

export const EXTRACTOR = {
  EXTRACTION: 'EXTRACTION',
  MANUAL: 'MANUAL',
} as const;

export type Extractor = ObjectValues<typeof EXTRACTOR>;

export interface TimelineEvent {
  id: UUID;
  key: string;
  date: string;
  toDate?: string;
  payload: string;
  sourceFileId: UUID;
  page: number;
  legalCaseId: UUID;
  status: Status;
  extractor: Extractor;
  answer: string;
}
