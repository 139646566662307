import '@/common/components/perfect-scrollbar/style.css';
import '@/app/styles/main.scss';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tippy.js/dist/tippy.css';
import '@/app/dayjs';

import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/vue';
import axios from 'axios';
import { detect } from 'detect-browser';
import mixpanel from 'mixpanel-browser';
import { createApp } from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import VueObserveVisibility from 'vue-observe-visibility';
import VueTippy from 'vue-tippy';
import VueApexCharts from 'vue3-apexcharts';

import App from '@/app/App.vue';
import { config } from '@/app/config';
import { i18nService } from '@/app/i18n/i18n.service';
import router from '@/app/router';
import vuetify from '@/app/vuetify';
import LMenu from '@/common/components/LMenu.vue';
import PerfectScrollbar from '@/common/components/perfect-scrollbar/index.js';
import { vueAnalyticsPlugin } from '@/common/services/analytics/analytics';
import { vueAuthPlugin } from '@/common/services/auth/auth.vue.plugin';
import logger from '@/common/services/logging';

function isSubdomainWithRedirect() {
  const parts = location.hostname.split('.');
  const subdomain = parts[0];
  if (subdomain !== 'app' && subdomain !== 'localhost') {
    const redirectUri = `${window.location.origin.replace(`${subdomain}.`, '')}/c/${subdomain}`;
    window.location.replace(redirectUri);
    return true;
  }
  return false;
}

function showUnsupportedDevicePage() {
  document.getElementById('loading-animation')!.style.display = 'none';
  document.getElementById('bad-device')!.style.display = 'flex';
}

function isSupportedDevice() {
  // NOTE(mba): allow segmentation review screen for all devices
  if (window.location.href.includes('/split/')) {
    return true;
  }

  const browser = detect();
  if (!browser) {
    showUnsupportedDevicePage();
    return false;
  }

  if (config.BLOCKED_OS.includes(browser.os)) {
    showUnsupportedDevicePage();
    return false;
  }

  // NOTE(mba): allow sharing screen for all browsers, we don't allow mobile devices
  if (window.location.href.includes('/sharing/')) {
    return true;
  }

  if (!config.SUPPORTED_BROWSERS.includes(browser.name)) {
    showUnsupportedDevicePage();
    return false;
  }

  return true;
}

function main() {
  if (isSubdomainWithRedirect()) {
    return;
  }

  if (!isSupportedDevice()) {
    return;
  }

  // MIXPANEL
  if (config.MIXPANEL.ENABLED) {
    logger.info('Mixpanel enabled');
    mixpanel.init(config.MIXPANEL.TOKEN, {
      api_host: 'https://mixpanel.global.legal-i.ch',
      ignore_dnt: true,
      // NOTE(mba): debug mode
      // debug: true,
    });
  }

  // HOTJAR
  if (config.HOTJAR.ENABLED) {
    logger.info('Hotjar enabled');
    Hotjar.init(config.HOTJAR.SITE_ID, 6, {
      debug: config.HOTJAR.DEBUG,
      nonce: 'rAnDoM',
    });
  }

  // INTERCOM
  if (config.INTERCOM.ENABLED) {
    logger.info('Intercom enabled');
    /* eslint-disable */
    (function () {
      var w = window as any;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/' + config.INTERCOM.APP_ID;
          var x = d.getElementsByTagName('script')[0];
          x.parentNode!.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    })();

    /* eslint-enable */
  }

  // setup axios
  axios.defaults.baseURL = config.API.HOST;

  const app = createApp(App);

  // SENTRY
  if (config.SENTRY.ENABLED) {
    logger.info('Sentry enabled');
    Sentry.init({
      app,
      environment: config.ENVIRONMENT,
      dsn: config.SENTRY.DSN,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracesSampleRate: 0.1,

      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  // setup vue plugins
  app.use(i18nService.vueI18n);
  app.use(router);
  app.use(vuetify);
  app.use(PerfectScrollbar);
  app.use(VueApexCharts);
  app.use(VueObserveVisibility);
  app.use(VueDOMPurifyHTML);
  app.use(vueAnalyticsPlugin);
  app.use(vueAuthPlugin);

  app.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    component: 'tippy', // => <tippy/>,
    // NOTE(dp): singleton seems to conflict with Vuetify or something else - it resets its placement to the default in 10-15 seconds
    componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
    defaultProps: {
      placement: 'bottom',
      arrow: false,
      theme: 'legali',
      // for debugging:
      // hideOnClick: false,
      // trigger: 'click',
    },
  });

  // global components registration
  app.component('Apexchart', VueApexCharts);
  app.component('LMenu', LMenu);

  // custom directive that prevents focus
  app.directive('blur', {
    mounted(el: HTMLElement) {
      el.onfocus = (ev) => (ev.target as HTMLElement).blur();
    },
  });

  app.mount('#app');
  document.getElementById('wrapper')!.style.display = 'none';
}

main();
