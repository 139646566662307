import { ISODateTimeString, ObjectValues, UUID } from '@/common/types/common.types';

export const AUTHORIZATION_GRANULARITY = {
  private: 'PRIVATE',
  restricted: 'RESTRICTED',
  default: 'DEFAULT',
  shared: 'SHARED',
} as const;
type AuthorizationGranularity = ObjectValues<typeof AUTHORIZATION_GRANULARITY>;

// See NotebookDTO.java
export interface Notebook {
  id: UUID;
  title: string;
  userId: string;
  content: string;
  authorizationGranularity: AuthorizationGranularity;
  updated: ISODateTimeString;
}
